var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "broadcasts-wrapper" }, [
    _c("div", { staticClass: "d-flex align-center" }, [
      _c(
        "div",
        [
          _c(
            "app-button",
            {
              staticClass: "py-2",
              attrs: {
                "data-test-id": "dashboard_create_broadcast",
                color: "night-rider",
              },
              on: { click: _vm.showAddBroadcastModal },
            },
            [
              _vm._v(" " + _vm._s(_vm.$t("broadcasts.createABroadcast")) + " "),
              _c("AppIcon", {
                staticClass: "ml-1",
                attrs: { size: "16", icon: "add" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "ml-3" }, [
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            !_vm.$isMobile
              ? _c("span", { staticClass: "filter" }, [
                  _vm._v(_vm._s(_vm.$t("broadcasts.filters")) + " :"),
                ])
              : _vm._e(),
            _c("text-filter", {
              attrs: {
                filters: _vm.filters,
                "selected-filter": _vm.selectedFilter,
              },
              on: { changeFilter: _vm.changeFilter },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm.loading
      ? _c(
          "div",
          [
            _c("v-progress-linear", {
              attrs: { indeterminate: "", color: "yellow darken-2" },
            }),
          ],
          1
        )
      : !_vm.hasAnySession
      ? _c("div", [_c("AppNoData")], 1)
      : _c(
          "div",
          {
            staticClass: "mt-3 broadcast-wrapper d-flex flex-column",
            class: {
              "justify-start": _vm.totalPages <= 1,
              "justify-space-between": _vm.totalPages > 1,
            },
          },
          [
            _c(
              "div",
              { staticClass: "broadcast-list" },
              [
                _vm.selectedFilter === _vm.BROADCAST_FILTERS.UPCOMING
                  ? _c("AppList", {
                      attrs: {
                        "data-test-id": "dashboard-upcoming-list",
                        listItems: _vm.liveListItems,
                        actions: _vm.liveActions,
                      },
                    })
                  : _vm._e(),
                _vm.selectedFilter === _vm.BROADCAST_FILTERS.UPCOMING &&
                _vm.liveListItems.length &&
                _vm.listItems.length
                  ? _c("v-divider", { staticClass: "mb-6 mt-3 mr-4" })
                  : _vm._e(),
                _c("AppList", {
                  attrs: {
                    "data-test-id": "dashboard-past-list",
                    listItems: _vm.listItems,
                    actions: _vm.actions,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "broadcast-pagenation" },
              [
                _vm.totalPages > 1 && !_vm.loading
                  ? _c("AppPagination", {
                      attrs: { page: _vm.page, length: _vm.totalPages },
                      on: { change: (v) => (_vm.page = v) },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }