var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex text-filter-wrapper" },
    _vm._l(_vm.filters, function (filter, key) {
      return _c(
        "span",
        {
          key: key,
          staticClass: "filter-by-text mx-1",
          class: { active: filter.value === _vm.selectedFilter },
          on: {
            click: function ($event) {
              return _vm.$emit("changeFilter", filter.value)
            },
          },
        },
        [_vm._v(" " + _vm._s(filter.text) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }