<template>
  <div class="broadcasts-wrapper">
    <div class="d-flex align-center">
      <div>
        <app-button data-test-id="dashboard_create_broadcast" color="night-rider" class="py-2" @click="showAddBroadcastModal">
          {{ $t('broadcasts.createABroadcast') }}
          <AppIcon class="ml-1" size="16" icon="add" />
        </app-button>
      </div>
      <div class="ml-3">
        <div class="d-flex align-center">
          <span v-if="!$isMobile" class="filter">{{ $t('broadcasts.filters') }} :</span>
          <text-filter :filters="filters" :selected-filter="selectedFilter" @changeFilter="changeFilter" />
        </div>
      </div>
    </div>
    <div v-if="loading"><v-progress-linear indeterminate color="yellow darken-2"></v-progress-linear></div>
    <div v-else-if="!hasAnySession">
      <AppNoData />
    </div>

    <div class="mt-3 broadcast-wrapper d-flex flex-column" v-else :class="{ 'justify-start': totalPages <= 1, 'justify-space-between': totalPages > 1 }">
      <div class="broadcast-list">
        <AppList
          v-if="selectedFilter === BROADCAST_FILTERS.UPCOMING"
          data-test-id="dashboard-upcoming-list"
          :listItems="liveListItems"
          :actions="liveActions"
        />
        <v-divider v-if="selectedFilter === BROADCAST_FILTERS.UPCOMING && liveListItems.length && listItems.length" class="mb-6 mt-3 mr-4"></v-divider>
        <AppList data-test-id="dashboard-past-list" :listItems="listItems" :actions="actions" />
      </div>
      <div class="broadcast-pagenation">
        <AppPagination v-if="totalPages > 1 && !loading" :page="page" :length="totalPages" @change="(v) => (page = v)" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { consoleError, consoleLog } from 'xpermeet-lib';

import TextFilter from '@/components/Common/TextFilter';
import AppList from '@/components/Common/AppList';
import AddEditBroadcastModal from '@/components/Modals/AddEditBroadcastModal';
import DeleteBroadcastModal from '@/components/Modals/DeleteBroadcastModal';
import InviteParticipantModal from '@/components/Modals/InviteParticipantModal';
import { BROADCAST_FILTERS } from '@/constants/enums';
import { NOTIFICATION, STUDIO } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'Broadcasts',
  components: { TextFilter, AppList },
  data() {
    return {
      BROADCAST_FILTERS,
      selectedFilter: BROADCAST_FILTERS.UPCOMING,
      page: 1,
      listItems: [],
      liveListItems: [],
      totalPages: 0,
      loading: false,
      filters: [
        {
          text: this.$t('broadcasts.upcoming'),
          value: BROADCAST_FILTERS.UPCOMING,
        },
        {
          text: this.$t('broadcasts.past'),
          value: BROADCAST_FILTERS.PAST,
        },
      ],
    };
  },
  computed: {
    inviteAGuestChild() {
      return {
        title: this.$t('broadcasts.inviteAGuest'),
        icon: 'user-add',
        hide: this.selectedFilter === 'finished',
        dataTestId: 'invite_a_guest',
        onClick: (item) => {
          consoleLog('broadcasts.inviteAGuest', item);
          const modal = this.$showModal(
            InviteParticipantModal,
            {
              sessionId: item?.raw?.sessionId,
              sessionName: item?.raw?.sessionName,
            },
            {
              close: () => {
                modal.close();
              },
            },
          );
        },
      };
    },

    deleteBroadcastChild() {
      return {
        title: this.$t('broadcasts.deleteBroadcast'),
        icon: 'trash',
        color: 'var(--v-error-base)',
        type: 'delete',
        dataTestId: 'delete',
        onClick: (broadcast) => {
          consoleLog('broadcasts.deleteBroadcast', broadcast);
          const modal = this.$showModal(
            DeleteBroadcastModal,
            { broadcast },
            {
              close: () => {
                modal.close();
              },
              deleted: () => {
                this.fetchData();
                this.showToastNotification({ body: this.$t('broadcasts.broadcastDeletedSuccessfully'), config: { type: TOAST_TYPE.SUCCESS } });
                modal.close();
              },
            },
          );
        },
      };
    },
    dividerChild() {
      return {
        type: 'divider',
      };
    },
    hideOnFinishedDividerChild() {
      return {
        type: 'divider',
        hide: this.selectedFilter === 'finished',
      };
    },
    liveChilds() {
      return [this.inviteAGuestChild, this.editChild(true), this.hideOnFinishedDividerChild];
    },
    childs() {
      return [this.inviteAGuestChild, this.editChild(false), this.hideOnFinishedDividerChild, this.deleteBroadcastChild];
    },
    actions() {
      return {
        ...this.actionTextAndEvent,
        childs: this.childs,
      };
    },
    actionTextAndEvent() {
      return {
        text: this.selectedFilter === 'finished' ? '' : 'Enter studio',
        onTextClick: (item) => {
          const sessionId = item?.raw?.sessionId;
          if (sessionId) {
            this.$router.push(`/prejoin/${sessionId}`);
          }
        },
      };
    },
    liveActions() {
      return {
        ...this.actionTextAndEvent,
        childs: this.liveChilds,
      };
    },
    query() {
      return {
        size: 10,
        status: this.selectedFilter,
        page: this.page,
      };
    },
    liveQuery() {
      return {
        size: 25,
        status: BROADCAST_FILTERS.LIVE,
        page: 1,
      };
    },
    hasAnySession() {
      return this.selectedFilter === BROADCAST_FILTERS.UPCOMING ? this.listItems.length || this.liveListItems.length : this.listItems.length;
    },
  },
  created() {
    if (this.$route.query?.errorCode) {
      this.showToastNotification({ body: this.$t(`error.destination.${this.$route.query?.errorCode}`), config: { type: TOAST_TYPE.WARNING } });
      this.$router.replace({});
    }

    this.fetchData();
  },
  methods: {
    ...mapActions(STUDIO, ['fetchSessions']),
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    changeFilter(val) {
      this.selectedFilter = val;
    },
    showAddBroadcastModal() {
      const modal = this.$showModal(
        AddEditBroadcastModal,
        {},
        {
          completed: (sessionId) => {
            modal.close();
            this.$router.push(`/prejoin/${sessionId}`);
          },
        },
      );
    },
    async fetchData() {
      this.loading = true;

      try {
        const response = await this.fetchSessions({ query: this.query });

        if (!response) {
          return;
        }

        this.totalPages = response.data.totalPages;
        this.listItems = response.data.content.map((data) => ({
          raw: data,
          id: data.sessionId,
          text: data?.sessionName || data.items[0]?.title || '-',
          broadcasts: { values: data.items.map((i) => i.providerShortCode), type: 'broadcast-list' },
          createdDate: data.cdate,
        }));
        if (this.selectedFilter === BROADCAST_FILTERS.UPCOMING) {
          const responseLiveSessions = await this.fetchSessions({ query: this.liveQuery });
          this.liveListItems = responseLiveSessions.data.content.map((data) => {
            const text = data?.sessionName || data.items[0]?.title || '-';
            return {
              raw: data,
              id: data.sessionId,
              text: `${text}`,
              textStatus: `(${this.$t('broadcasts.live')})`,
              broadcasts: { values: data.items.map((i) => i.providerShortCode), type: 'broadcast-list' },
              createdDate: data.cdate,
            };
          });
        }
      } catch (err) {
        consoleError(err);
      }

      this.loading = false;
    },
    editChild(isLive) {
      return {
        title: this.$t('edit'),
        icon: 'pencil',
        hide: this.selectedFilter === 'finished',
        dataTestId: 'edit',
        onClick: (item) => {
          const modal = this.$showModal(
            AddEditBroadcastModal,
            { broadcast: item, isLive },
            {
              completed: () => {
                modal.close();
                this.fetchData();
              },
            },
          );
        },
      };
    },
  },
  watch: {
    query: 'fetchData',
  },
};
</script>

<style lang="scss" scoped>
.broadcasts-wrapper {
  height: 100%;
  .filter {
    font-size: 11px;
    line-height: 13px;
  }
  .broadcast-wrapper {
    height: 95%;
    .broadcast-list {
      max-height: 70vh;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
</style>

