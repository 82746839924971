<template>
  <div class="d-flex text-filter-wrapper">
    <span
      class="filter-by-text mx-1"
      v-for="(filter, key) in filters"
      :key="key"
      :class="{ active: filter.value === selectedFilter }"
      @click="$emit('changeFilter', filter.value)"
    >
      {{ filter.text }}
    </span>
  </div>
</template>
<script>
export default {
  name: 'TextFilter',
  props: {
    filters: {
      type: Array,
      required: true,
    },
    selectedFilter: {
      type: [String, Number],
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.text-filter-wrapper {
  .filter-by-text {
    cursor: pointer;
    font-size: 11px;
    line-height: 13px;
    color: var(--v-gray-base);
    &.active {
      color: var(--v-white-base);
    }
  }
}
</style>
